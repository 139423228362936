<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form
        method="post"
        action="javascript:void(0)"
        ref="form_marksheet"
        class="repeater-form"
        @submit="showProcessedResultInfo"
      >
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Class") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('class')"
                rules="required"
              >
                <select
                  v-model="classes_id"
                  class="form-control"
                  name="classes_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(cls, key) in classes"
                    :key="key"
                    :value="cls.id"
                  >
                    {{ cls.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Version") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('version')"
                rules="required"
              >
                <select
                  v-model="version_id"
                  class="form-control"
                  name="version_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(ver, key) in versions"
                    :key="key"
                    :value="ver.id"
                  >
                    {{ ver.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <!-- <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Shift") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('shift')"
                rules="required"
              >
                <select
                  v-model="shift_id"
                  class="form-control"
                  name="shift_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(shift, key) in shifts"
                    :key="key"
                    :value="shift.id"
                  >
                    {{ shift.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Wing") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('wing')"
                rules="required"
              >
                <select
                  v-model="wing_id"
                  class="form-control"
                  name="wing_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(wing, key) in wings"
                    :key="key"
                    :value="wing.id"
                  >
                    {{ wing.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Student group") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('student group')"
                rules="required"
              >
                <select
                  v-model="student_group_id"
                  class="form-control"
                  name="student_group_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(grp, key) in student_groups"
                    :key="key"
                    :value="grp.id"
                  >
                    {{ grp.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Academic Year") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('academic year')"
                rules="required"
              >
                <select
                  v-model="academic_year_id"
                  class="form-control"
                  name="academic_year_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(year, key) in academic_years"
                    :key="key"
                    :value="year.id"
                  >
                    {{ year.year }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t("Exam type") }} </span>
              </b-card-text>
              <validation-provider
                #default="{ errors }"
                :name="$t('exam type')"
                rules="required"
              >
                <select
                  v-model="exam_type_id"
                  class="form-control"
                  name="exam_type_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <option :value="null">Select one</option>
                  <option
                    v-for="(eType, key) in active_exam_types"
                    :key="key"
                    :value="eType.id"
                  >
                    {{ eType.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" class="mt-2 pt-1">
            <b-button variant="outline-primary" type="submit">
              {{ $t("Show Processed Info") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
        :data="getData"
        :columns="columns10"
        :filename="'filename'"
        :sheetname="'results'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="generateMarkSheets()">
        <b>Download Marksheets</b>
      </button>
    </div>
    <vue-good-table
      style="white-space: nowrap; text-align: center; vertical-align: middle"
      :columns="columns"
      :rows="marksheets"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <!-- Column: index -->
        <!-- <span v-else-if="props.column.field === 'student_id'">
          {{ cadetIdByID(props.row.student_id) }}
        </span> -->
        <span v-else-if="props.column.field === 'userable.name'">
          {{ studentNameByID(props.row.student_id) }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'shift_id'">
          {{ shiftByID(props.row.shift_id) }}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'exam_type_id'">
          {{ examTypeByID(props.row.exam_type_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button variant="success" @click="downloadMarksheetByStudent(props.row.student_id)">
             <feather-icon icon="DownloadIcon" class="mr-50"/>
          </b-button>
          <span>
            <template>
              <router-link :to="{ path: `/single/result${props.row.id}` }">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-login
                  variant="primary"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                </b-button>
              </router-link>
            </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['5', '10', '15']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from "vue-json-to-csv";
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
import { required } from "@validations";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import constants from  '@core/utils/constants';
import apiCall from "@/libs/axios";
export default {
  name: "Result",
  components: {
    BCardCode,
    VueGoodTable,
    BForm,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCardText,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VueJsonToCsv,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      classes_id: null,
      version_id: null,
      shift_id: null,
      wing_id: null,
      student_group_id: null,
      academic_year_id: null,
      exam_type_id: null,
      required,
      pageLength: 10,
      marksheets: [],
      dir: false,
      columns: [
        {
          label: "SL #",
          field: "id",
        },
        {
          label: "Cadet No",
          field: "student.cadet_no",
        },
        {
          label: "Name",
          field: "userable.name",
        },
        {
          label: "Wing",
          field: "wing_id",
        },
        {
          label: "Student Group",
          field: "student_group_id",
        },
        {
          label: "Exam Type",
          field: "exam_type_id",
        },
        {
          label: "Academic Year",
          field: "academic_year_id",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      columns10: [
        {
          label: "Cadet No",
          field: "student_id",
        },
        {
          label: "Class",
          field: "Class",
        },
        {
          label: "Version",
          field: "Version",
        },
        {
          label: "Shift",
          field: "Shift",
        },
        {
          label: "Wing",
          field: "Wing",
        },
        {
          label: "Student Group",
          field: "StudentGroup",
        },
        {
          label: "Exam Name",
          field: "ExamName",
        },
        {
          label: "Academic Year",
          field: "AcademicYear",
        },
        {
          label: "Status",
          field: "Status",
        },
      ],
    };
  },
  methods: {
    
    showProcessedResultInfo() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let data = new FormData(this.$refs.form_marksheet);

          apiCall
            .post("/get/results/for/marksheet", data)
            .then((response) => {
              if (response.data.status === "success") {
                this.marksheets = response.data.marksheet;
                this.marksheets.sort( this.compare );
              } else {
                this.$toaster.error(response.data.message);
              }
            })
            .catch((error) => {
              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).map((field) => {
                  this.$toaster.error(error.response.data.errors[field][0]);
                });
              } else this.$toaster.error(error.response.data.message);
            });
        }
      });
    },

    compare( a, b ) {
      if ( parseInt(a.student.cadet_no) < parseInt(b.student.cadet_no) ){
        return -1;
      }
      if ( parseInt(a.student.cadet_no) > parseInt(b.student.cadet_no) ){
        return 1;
      }
      return 0;
    },

    downloadMarksheetByStudent(id){
      /*only change able*/
      let title = "Results";
      let cadet_number= this.cadetIdByID(id);
      let data = new FormData(this.$refs.form_marksheet);
      
      data.append("title", title);
      data.append("student_id", id);
     
      //data.append("exam_type_id", this.exam_type_id);
      
      apiCall
        .post("/download/student/marksheet", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, cadet_number +"_marksheet.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },
    generateMarkSheets() {
      
    let data = new FormData(this.$refs.form_marksheet);
      apiCall
        .post("/generate/marksheets/for/all/students", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "exam_results.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    },
  },
  computed: {
    active_exam_types() {
      return this.$store.getters.active_exam_types;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    studentList() {
      return this.students.filter((item) => {
        if (
          item.classes_id === parseInt(this.classes_id) &&
          item.version_id === parseInt(this.version_id) &&
          item.shift_id === parseInt(this.shift_id) &&
          item.wing_id === parseInt(this.wing_id) &&
          (item.student_group_id === parseInt(this.student_group_id) || 
          item.student_group_id === parseInt(constants.COMMON_GROUP_ID) ) &&
          item.academic_year_id === parseInt(this.academic_year_id)
        )
          return item;
      });
    },
    ...mapGetters([
      "classes",
      "classByID",
      "versions",
      "versionByID",
      "shifts",
      "shiftByID",
      "wings",
      "wingByID",
      "student_groups",
      "student_groupByID",
      "academic_years",
      "academic_yearByID",
      "students",
      "studentIdByID",
      "studentNameByID",
      "active_exam_types",
      "examTypeByID",
      "cadetIdByID",
    ]),
    getData() {
      return this.marksheets.map((item) => ({
        CadetNo: item.student_id ? this.cadetIdByID(item.student_id) : "TBD",
        Name: item.student_id ? this.studentNameByID(item.student_id) : "",
        Wing: item.wing_id ? this.wingByID(item.wing_id) : "",
        Group: item.student_group_id
          ? this.student_groupByID(item.student_group_id)
          : "",
        Examination: item.exam_type_id
          ? this.examTypeByID(item.exam_type_id)
          : "",
        AcademicYear: item.academic_year_id
          ? this.academic_yearByID(item.academic_year_id)
          : "",
        Status: item.is_passed ? "Pass" : "Fail",
      }));
    },
  },
  created() {
    if (this.classes.length < 1) this.$store.dispatch("GET_ALL_CLASSES");
    if (this.versions.length < 1) this.$store.dispatch("GET_ALL_VERSION");
    if (this.shifts.length < 1) this.$store.dispatch("GET_ALL_SHIFT");
    if (this.wings.length < 1) this.$store.dispatch("GET_ALL_WING");
    if (this.student_groups.length < 1)
      this.$store.dispatch("GET_ALL_STUDENT_GROUP");
    if (this.academic_years.length < 1)
      this.$store.dispatch("GET_ALL_ACADEMIC_YEAR");

    if (this.active_exam_types.length < 1) this.$store.dispatch("GET_ACTIVE_EXAM_TYPE");
    if (this.students.length < 1) this.$store.dispatch("GET_ALL_STUDENT");
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>